<template>
  <div>
  <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="5"
      class="elevation-1"
  ></v-data-table>

    <v-row>
      <v-text-field label="Name" />
      <v-text-field label="Test" />
    </v-row>

  </div>
</template>

<script>
export default {
  data () {
    return {
      headers: [
        {text: 'Title', value: 'title.en',},
        { text: 'Category', value: 'category' },
        { text: 'Color', value: 'color' },
        { text: 'icon', value: 'iconName' },

      ],
      desserts: [
        {
          "_id" : "6119d46109854d4398121335",
          "equipmentCode" : "",
          "lifeTime" : null,
          "behaviours" : [
            {
              "type" : "poison-enemy",
              "gamePlayImpact" : {
                "timeOfImpactInDuration" : 5,
                "totalDurationInSecond" : 60,
                "subDurationImpactInSecond" : 0,
                "value" : 10,
                "valueType" : "percentage",
                "visualEffectOfTargets" : [
                  {
                    "effect" : "poison",
                    "targets" : [
                      "hero"
                    ]
                  }
                ]
              }
            }
          ],
          "qty" : 987,
          "category" : "potion",
          "seller" : "HokMok Store",
          "color" : "rouge",
          "availableForSale" : true,
          "iconName" : "_weapon_potion_07",
          "costPerUnit" : "2.1",
          "title" : {
            "en" : "Poison smelling",
            "fr" : "Poison smelling",
            "es" : "Poison smelling",
            "km" : "Poison smelling"
          },
          "description" : {
            "en" : "Make enemy poison in 60s, and dmg 10% per 5s",
            "fr" : "Make enemy poison in 60s, and dmg 10% per 5s",
            "es" : "Make enemy poison in 60s, and dmg 10% per 5s",
            "km" : "Make enemy poison in 60s, and dmg 10% per 5s"
          },
          "targetSelect" : [
            "menu"
          ],
          "level" : 1,
          "equipmentType" : "menu",
          "createdAt" : "2021-08-16T02:58:41.632Z",
          "updatedAt" : "2021-10-21T08:55:03.614Z",
          "__v" : 0,
          "id" : "6119d46109854d4398121335",
          "gameLevel" : {
            "levelStart" : -1,
            "levelEnd" : -1
          }
        },
      ],
    }
  },
}
</script>
